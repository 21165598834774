
.center{
  display: flex;
  justify-content: center;
  align-items: center;
}

:root {


  --raw-seinna: hsl(24, 74%, 58%);
  --sizzling-sunrise: hsl(51, 95%, 54%);
  --scarlet: hsl(13, 96%, 47%);
  --black: hsl(0, 0%, 0%);
  --white: hsl(0, 0%, 100%);
  --input-bg: hsl(0, 0%, 93%);
  --bg-primary: hsl(0, 0%, 90%);
  --bg-secondary: hsl(0, 0%, 100%);

  /**
   * typography
   */

  --ff-saira-stencil-one: "Saira Stencil One", sans-serif; 
  --ff-poppins: 'Poppins', sans-serif;
  --ff-roboto: 'Roboto', sans-serif;

  --fs-1: 2rem;
  --fs-2: calc(1.813rem + 1vw);
  --fs-3: calc(1.313rem + 1vw);
  --fs-4: 1.4rem;
  --fs-5: 1rem;
  --fs-6: 0.813rem;
  --fs-7: 0.75rem;

  --fw-400: 400;
  --fw-700: 700;

  /**
   * transition
   */

  --transition-1: 0.25s ease-in-out;

  /**
   * spacing
   */

  --section-padding: 80px;

  /**
   * radius
   */

  --radius-4: 4px;
  --radius-12: 12px;

}


::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, .5);
  border-radius: 10px;
  border: 2px solid #ffffff;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}


/*-----------------------------------*\
 * #THEME COLORS
\*-----------------------------------*/




/*-----------------------------------*\
 * #RESET
\*-----------------------------------*/

*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a { text-decoration: none; }

a,
img,
time,
span,
input,
label,
select,
button,
textarea,
ion-icon { display: block; }

input,
button,
select,
textarea {
  background: none;
  border: none;
  font: inherit;
}

button,
select { cursor: pointer; }

input,
textarea { width: 100%; }

ion-icon { pointer-events: none; }

:is(a, button, select) {
  outline-color: var(--scarlet);
  outline-offset: 3px;
}



html {
  font-family: var(--ff-roboto);
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-primary);
  transition: var(--transition-1);
}

body.active { overflow: hidden; }





/*-----------------------------------*\
 * #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 10px; }

.h1 {
  font-size: var(--fs-1);
  line-height: 1.2;
  font-weight: 400;
}

.h2,
.h3,
.h4 {
  color: var(--color-primary);
  font-family: var(--ff-poppins);
  line-height: 1.2;
}

.h2 { font-size: var(--fs-2); }

.h3 { font-size: var(--fs-3); }

.h4 { font-size: var(--fs-5); }

.w-100 { width: 100%; }

.button {
  max-width: max-content;
  color: var(--color-primary);
  font-weight: var(--fw-700);
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 10px 25px;
  border: 1px solid transparent;
  border-radius: var(--radius-4);
  transition: var(--transition-1);
}

.button-primary {
  text-decoration: none;
  border-color: var(--color-primary);
 }

.button-primary:is(:hover, :focus) {
  background: var(--raw-seinna);
  color: var(--bg-primary);
}

.button-secondary {
  background: var(--raw-seinna);
  border-color: var(--raw-seinna);
  color: var(--white);
}

.button-secondary:is(:hover, :focus) { --raw-seinna: hsl(24, 74%, 64%); }

.section-subtitle {
  position: relative;
  color: var(--color-secondary);
  text-transform: uppercase;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

.section-subtitle::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0;
  width: 60px;
  height: 2px;
  background: var(--color-secondary);
}

.section-title {
  max-width: 350px;
  margin-bottom: 30px;
}

.section-text {
  color: var(--color-secondary);
  line-height: 1.8;
  margin-bottom: 30px;
}

.tooltip {
  position: absolute;
  top: -40px;
  background: white;
  min-width: max-content;
  color: var(--white);
  font-size: 15px;
  font-weight: var(--fw-700);
  padding: 5px 10px;
  border-radius: var(--radius-4);
  box-shadow: var(--shadow-1);
  transform: translateY(-10px);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition-1);
  z-index: 1;
}





/*-----------------------------------*\
 * #HEADER
\*-----------------------------------*/

.nav.nav-pills .nav-link.active {
    background-color: var(--raw-seinna);
    color: white;
}
.navLink:hover {
  color: white;
}
.nav.nav-pills .nav-link{
  color: black;
}

/*-----------------------------------*\
 * #HERO
\*-----------------------------------*/

/* main { overflow-x: hidden; } */

.hero {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  margin-bottom: var(--section-padding);
}

.hero-banner {
  max-width: 500px;
  width: 100%;
  margin-bottom: 30px;
}

:is(.hero-social-list, .scroll-down) { display: none; }

.hero-content { max-width: 450px; }

.hero-title {
  text-align: center;
  margin-bottom: 30px;
}

.hero .button-primary { margin-inline: auto; }





/*-----------------------------------*\
 * #STATS
\*-----------------------------------*/

.stats { padding-block: var(--section-padding); }

.stats-list {
  display: grid;
  gap: 30px;
}

.stats-card {
  background: var(--bg-secondary);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 25px;
  border-radius: var(--radius-12);
  box-shadow: var(--shadow-1);
  transition: var(--transition-1);
}

.stats-card:is(:hover, :focus) { transform: translateY(-5px); }

.stats-card .card-icon {
  background: var(--raw-seinna);
  width: 60px;
  height: 60px;
  display: grid;
  place-items: center;
  border-radius: 50%;
}

.stats-card .card-icon img {
  width: 70%;
  filter: invert(1);
}

.stats-card .card-title {
  width: calc(100% - 95px);
  color: var(--raw-seinna);
  text-align: center;
}

.stats-card .card-title strong {
  display: block;
  color: var(--color-primary);
  font-size: initial;
  line-height: 1.3;
  margin-top: 5px;
}

.stats-card > ion-icon { color: var(--color-secondary); }




/*-----------------------------------*\
 * #SKILLS
\*-----------------------------------*/

.skills-box.active .skills-list,
.skills-box .tools-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  animation: fadeIn 0.5s ease-out forwards;}

.skills-box .skills-list,
.skills-box.active .tools-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px;
  animation: fadeIn 0.5s ease-out forwards;
}

@keyframes fadeIn {

  0% { opacity: 0; }
  100% { opacity: 1; }

}

.skill-card {
  position: relative;
  background: var(--bg-secondary);
  width: 80px;
  height: 80px;
  display: grid;
  place-items: center;
  border-radius: var(--radius-12);
  box-shadow: var(--shadow-1);
  cursor: help;
}

.skill-card:hover .tooltip {
  transform: translateY(0);
  opacity: 1;
}





/*-----------------------------------*\
 * #PROJECT
\*-----------------------------------*/

.project { padding-block: var(--section-padding); }

.project-list > li { margin-bottom: 25px; }

.project-card {
  position: relative;
  overflow: hidden;
  border-radius: var(--radius-12);
  box-shadow: 2px 4px 6px hsla(0, 0%, 0%, 0.2);
}

.project-card::after {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: inherit;
  transition: var(--transition-1);
}

.project-card:is(:hover, :focus)::after {
  background: hsla(0, 0%, 100%, 0.1);
  backdrop-filter: blur(0.5px);
}

.project-card .card-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
}

.project-card .card-title { margin-bottom: 10px; }

.project-card :is(.card-title, .publish-date) {
  background: var(--bg-primary);
  padding: 6px 12px;
  width: max-content;
  box-shadow: var(--shadow-1);
  transform: translateY(20px);
  opacity: 0;
  transition: var(--transition-1);
}

.project-card .publish-date {
  font-size: var(--fs-6);
  color: var(--color-primary);
  transition-delay: 0s;
}

.project-card:is(:hover, :focus) :is(.card-title, .publish-date) {
  transform: translateY(0);
  opacity: 1;
}

.project-card:is(:hover, :focus) .publish-date { transition-delay: 0.1s; }

.load-more {
  font-size: var(--fs-4);
  color: var(--raw-seinna);
  text-decoration: underline;
  margin-inline: auto;
}





/*-----------------------------------*\
 * #CONTACT
\*-----------------------------------*/

.contact { padding-block: var(--section-padding); }

.contact-content { margin-bottom: 50px; }

.contact-list-item {
  display: flex;
  align-items: flex-start;
  gap: 15px;
  margin-bottom: 20px;
}

.contact-item-icon {
  font-size: 25px;
  color: var(--color-primary);
}

.contact-item-icon ion-icon { --ionicon-stroke-width: 40px; }

.contact-item-title { margin-bottom: 10px; }

.contact-list-item .contact-info {
  color: var(--color-secondary);
  font-style: normal;
  line-height: 1.6;
  transition: var(--transition-1);
}

.contact-info:not(address):is(:hover, :focus) { color: var(--color-primary); }

.contac-social-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding-inline: 40px;
}

.contact-social-link {
  position: relative;
  background: var(--color-primary);
  color: var(--bg-primary);
  width: 35px;
  height: 35px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  transition: var(--transition-1);
}

.contact-social-link:is(:hover, :focus) {
  background: var(--raw-seinna);
  color: var(--white);
}

.contact-social-link:is(:hover, :focus) .tooltip {
  transform: translateY(0);
  opacity: 1;
}

.contact-form {
  background: var(--bg-secondary);
  padding: 20px;
  border-radius: var(--radius-12);
  box-shadow: var(--shadow-1);
}

.form-wrapper { margin-bottom: 25px; }

.form-label {
  color: var(--color-primary);
  font-size: var(--fs-7);
  font-family: var(--ff-poppins);
  margin-bottom: 8px;
  margin-left: 5px;
}

.input-wrapper { position: relative; }

.input-field {
  background: var(--input-bg);
  color: var(--raw-seinna);
  padding: 10px;
  padding-left: 40px;
  border-radius: var(--radius-12);
  transition: var(--transition-1);
}

.input-wrapper ion-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  color: var(--color-secondary);
  font-size: 20px;
  transition: var(--transition-1);
}

textarea.input-field {
  min-height: 60px;
  height: 120px;
  max-height: 200px;
  resize: vertical;
}

.input-field:focus { outline: 1px solid; }

.input-field:focus + ion-icon { color: var(--raw-seinna); }

.contact .button-primary {
  max-width: unset;
  width: 100%;
  background: var(--color-primary);
  color: var(--bg-primary);
}





/*-----------------------------------*\
 * #FOOTER
\*-----------------------------------*/

.footer {
  background: var(--bg-secondary);
  padding-block: 20px;
  text-align: center;
  box-shadow: var(--shadow-1);
}

.footer .logo { margin-bottom: 15px; }

.copyright {
  color: var(--color-secondary);
  line-height: 1.6;
}

.copyright a {
  display: inline-block;
  color: var(--raw-seinna);
}





/*-----------------------------------*\
 * #GO TO TOP
\*-----------------------------------*/

.go-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  width: 45px;
  height: 45px;
  display: grid;
  place-items: center;
  font-size: 20px;
  color: var(--color-primary);
  border: 2px solid var(--color-primary);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: var(--transition-1);
  z-index: 2;
}

.go-top.active {
  opacity: 1;
  pointer-events: all;
  transform: translateY(0);
}








