.st {
    position: relative;
    outline-style: none; }
    .st:focus {
      outline-style: none; }
    .st *,
    .st *::before,
    .st *::after {
      box-sizing: border-box; }
    .st > .tab-content {
      position: relative;
      overflow: hidden; }
      .st > .tab-content > .tab-pane {
        display: none; }
        .st > .tab-content > .tab-pane.active {
          display: block; }
    .st > .nav {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 0; }
      @media screen and (max-width: 640px) {
        .st > .nav {
          flex-direction: column !important;
          flex: 1 auto; } }
      .st > .nav .nav-link {
        display: block;
        padding: .5rem 1rem;
        text-decoration: none;
        outline: 0 !important; }
        .st > .nav .nav-link:hover, .st > .nav .nav-link:active, .st > .nav .nav-link:focus {
          text-decoration: none;
          outline: 0 !important; }
        .st > .nav .nav-link::-moz-focus-inner {
          border: 0 !important; }
        .st > .nav .nav-link.disabled {
          color: #ccc !important;
          pointer-events: none;
          cursor: default; }
    .st.st-vertical {
      display: flex !important;
      flex-wrap: nowrap; }
      .st.st-vertical > .nav {
        flex-direction: column !important;
        flex: 1 auto; }
        .st.st-vertical > .nav .nav-link {
          text-align: left; }
        .st.st-vertical > .nav > li, .st.st-vertical > .nav .nav-link {
          flex-grow: unset !important; }
      .st.st-vertical > .tab-content {
        flex: 1 100%; }
    .st.st-justified > .nav > li, .st.st-justified > .nav .nav-link {
      flex-basis: 0;
      flex-grow: 1;
      text-align: center; }
    .st.st-loading {
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }
      .st.st-loading::after {
        content: "";
        display: block;
        position: absolute;
        opacity: 1;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(255, 255, 255, 0.7);
        z-index: 2;
        transition: all .2s ease; }
      .st.st-loading::before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 45%;
        left: 45%;
        width: 2rem;
        height: 2rem;
        border: 10px solid #f3f3f3;
        border-top: 10px solid #3498db;
        border-radius: 50%;
        z-index: 10;
        -webkit-animation: spin 1s linear infinite;
                animation: spin 1s linear infinite; }
  
  @-webkit-keyframes spin {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg); }
    100% {
      transform: rotate(360deg); } }
  
  /* SmartTab Theme: Default */

    .st-theme-default > .tab-content > .tab-pane {
      padding: 10px;
    
    }
    .st-theme-default > .nav {
      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1) !important; 
    }
      .st-theme-default > .nav .nav-link {
        position: relative;
        background: transparent;
        border: transparent;
        height: 100%;
        min-height: 100%;
        color: #222 !important;
        padding: 10px;
        transition: all 250ms ease 0s;
       }
        .st-theme-default > .nav .nav-link::after {
          content: "";
          background: transparent;
          height: 2px;
          position: absolute;
          width: 100%;
          left: 0px;
          bottom: -1px;
          transition: all 250ms ease 0s;
          transform: scale(0);
         }

        .st-theme-default > .nav .nav-link.active {
          color: var(--raw-seinna) !important;
          cursor: pointer;
         }
          .st-theme-default > .nav .nav-link.active::after {
            background: var(--raw-seinna) !important;
            transform: scale(1);
           }
    .st-theme-default.st-vertical > .nav {
      box-shadow: none!important;
     }
      .st-theme-default.st-vertical > .nav .nav-link::after {
        height: 100%;
        width: 2px;
        top: 0px;
        left: auto;
        right: -1px; }
  
  /* SmartTab Theme: Classic */
  .st-theme-classic {
    border: 1px solid #eee; }
    .st-theme-classic > .tab-content > .tab-pane {
      padding: 10px; }
    .st-theme-classic > .nav .nav-link {
      position: relative;
      background: transparent;
      border: transparent;
      height: 100%;
      min-height: 100%;
      color: #222 !important;
      padding: 10px;
      background-color: #f9f9f9;
      background-image: linear-gradient(to bottom, #f9f9f9 0%, #f6f6f6 47%, #ededed 100%); }
      .st-theme-classic > .nav .nav-link.active {
        color: #5bc0de !important;
        background-image: linear-gradient(to top, #ffffff 0%, #f6f6f6 47%, #ededed 100%);
        cursor: pointer; }
    .st-theme-classic.st-vertical > .nav .nav-link {
      background-image: linear-gradient(to right, #f9f9f9 0%, #f6f6f6 47%, #ededed 100%); }
      .st-theme-classic.st-vertical > .nav .nav-link.active {
        background-image: linear-gradient(to left, #ffffff 0%, #f6f6f6 47%, #ededed 100%); }
  
  /* SmartTab Theme: Dark */
  .st-theme-dark {
    border: 1px solid #000000;
    color: rgba(255, 255, 255, 0.95);
    background: #181c20; }
    .st-theme-dark > .tab-content > .tab-pane {
      padding: 10px; }
    .st-theme-dark > .nav {
      background: #0D0F12; }
      .st-theme-dark > .nav .nav-link {
        position: relative;
        background: transparent;
        border: transparent;
        height: 100%;
        min-height: 100%;
        color: #aaaaaa !important;
        padding: 10px; }
        .st-theme-dark > .nav .nav-link.active {
          color: rgba(255, 255, 255, 0.95) !important;
          background: #181c20 !important;
          cursor: pointer; }
        .st-theme-dark > .nav .nav-link.disabled {
          color: #555555 !important; }
  
  /* SmartTab Theme: Brick */
  .st-theme-brick > .tab-content > .tab-pane {
    padding: 10px; }
  
  .st-theme-brick > .nav {
    background: #f9f9f9;
    border-bottom: 2px solid #5bc0de; }
    .st-theme-brick > .nav .nav-link {
      position: relative;
      background: transparent;
      border: transparent;
      height: 100%;
      min-height: 100%;
      color: #bbbbbb !important;
      padding: 10px; }
      .st-theme-brick > .nav .nav-link.active {
        color: #ffffff !important;
        background: #5bc0de !important;
        cursor: pointer;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px; }
      .st-theme-brick > .nav .nav-link.disabled {
        color: #eeeeee !important; }
  
  .st-theme-brick.st-vertical > .nav {
    border-right: 2px solid #5bc0de;
    border-bottom: unset; }
    .st-theme-brick.st-vertical > .nav .nav-link.active {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: unset; }
  
  /* SmartTab Theme: Round */
  .st-theme-round {
    border: 1px solid #dee2e6;
    border-top-width: 0;
    border-radius: 8px; }
    .st-theme-round > .tab-content > .tab-pane {
      padding: 10px; }
    .st-theme-round > .nav {
      background: #ffffff;
      border-bottom: 1px solid #dee2e6;
      margin-right: -1px;
      margin-left: -1px; }
      .st-theme-round > .nav .nav-link {
        position: relative;
        background: transparent;
        height: 100%;
        min-height: 100%;
        color: #bbbbbb !important;
        padding: 10px;
        border: 1px solid transparent;
        border-bottom-width: 0;
        background: #ffffff; }
        .st-theme-round > .nav .nav-link.active {
          color: #495057 !important;
          border-color: #dee2e6 !important;
          cursor: pointer;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px; }
          .st-theme-round > .nav .nav-link.active::after {
            content: "";
            background: #ffffff;
            height: 1px;
            position: absolute;
            width: 100%;
            left: 0px;
            bottom: -1px; }
        .st-theme-round > .nav .nav-link.disabled {
          color: #eeeeee !important; }
    .st-theme-round.st-vertical {
      border-top-width: 1px;
      border-left-width: 0; }
      .st-theme-round.st-vertical > .nav {
        border-right: 1px solid #dee2e6;
        border-bottom: unset;
        margin-top: -1px;
        margin-bottom: -1px;
        margin-right: 0;
        margin-left: 0; }
        .st-theme-round.st-vertical > .nav .nav-link {
          border-bottom-width: 1px;
          border-right-width: 0; }
          .st-theme-round.st-vertical > .nav .nav-link.active {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-top-right-radius: unset; }
            .st-theme-round.st-vertical > .nav .nav-link.active::after {
              height: 100%;
              width: 1px;
              top: 0px;
              left: auto;
              right: -1px; }
  
  /* SmartTab Theme: Pills */
  .st-theme-pills > .tab-content > .tab-pane {
    padding: 10px; }
  
  .st-theme-pills > .nav {
    background: #5bc0de;
    padding: 11px 23px;
    border-radius: 0.1875rem; }
    .st-theme-pills > .nav .nav-link {
      height: 100%;
      min-height: 100%;
      color: #fff !important;
      padding: .5rem 1rem; }
      .st-theme-pills > .nav .nav-link.active {
        color: #fff !important;
        background: rgba(255, 255, 255, 0.2);
        border-radius: 30px;
        cursor: pointer; }
  
  /* SmartTab Theme: GitHub */
  .st-theme-github > .tab-content > .tab-pane {
    padding: 10px; }
  
  .st-theme-github > .nav {
    background: #fafbfc;
    border-bottom: 1px solid #e1e4e8;
    margin-right: -1px;
    margin-left: -1px; }
    .st-theme-github > .nav .nav-link {
      position: relative;
      background: transparent;
      height: 100%;
      min-height: 100%;
      color: #586069;
      padding: 10px;
      border: 1px solid transparent;
      border-top-width: 3px;
      border-bottom-width: 0;
      background: #fafbfc; }
      .st-theme-github > .nav .nav-link:hover, .st-theme-github > .nav .nav-link:focus {
        color: #24292e; }
      .st-theme-github > .nav .nav-link.active {
        color: #495057 !important;
        border-color: #e1e4e8 !important;
        border-top-color: #e36209 !important;
        background-color: #ffffff !important;
        cursor: pointer; }
        .st-theme-github > .nav .nav-link.active::after {
          content: "";
          background: #ffffff;
          height: 1px;
          position: absolute;
          width: 100%;
          left: 0px;
          bottom: -1px; }
      .st-theme-github > .nav .nav-link.disabled {
        color: #eeeeee !important; }
  
  .st-theme-github.st-vertical > .nav {
    border-right: 1px solid #e1e4e8;
    border-bottom: unset;
    margin-top: -1px;
    margin-bottom: -1px;
    margin-right: 0;
    margin-left: 0; }
    .st-theme-github.st-vertical > .nav .nav-link {
      border-bottom-width: 1px;
      border-right-width: 0;
      border-top-width: 1px;
      border-left-width: 3px; }
      .st-theme-github.st-vertical > .nav .nav-link.active {
        border-top-color: #e1e4e8 !important;
        border-left-color: #e36209 !important; }
        .st-theme-github.st-vertical > .nav .nav-link.active::after {
          height: 100%;
          width: 1px;
          top: 0px;
          left: auto;
          right: -1px; }
  
  /* SmartTab Theme: Sourceforge */
  .st-theme-sourceforge > .tab-content > .tab-pane {
    padding: 11px 15px; }
  
  .st-theme-sourceforge > .nav {
    background: #fff;
    border-right: 1px solid;
    border-image-source: linear-gradient(to bottom, #333333 0%, #333333 3px, #dcdcdc 3px);
    border-image-slice: 0 1 0 0; }
    .st-theme-sourceforge > .nav .nav-link {
      position: relative;
      height: 100%;
      min-height: 100%;
      padding: 10px;
      color: #586069;
      background: #fff;
      border-style: solid;
      border-width: 3px 0 1px 1px;
      border-image-source: linear-gradient(to bottom, #333333 0%, #333333 3px, #dcdcdc 3px);
      border-image-slice: 3 0 1 1; }
      .st-theme-sourceforge > .nav .nav-link:hover, .st-theme-sourceforge > .nav .nav-link:focus {
        color: #09c !important; }
      .st-theme-sourceforge > .nav .nav-link.active {
        color: #0cf !important;
        background-color: #ffffff !important;
        cursor: pointer;
        border-image-source: linear-gradient(to bottom, #00ccff 0%, #00ccff 3px, #dcdcdc 3px);
        border-image-slice: 3 0 0 1; }
  
  .st-theme-sourceforge.st-vertical > .nav {
    border-top: 1px solid;
    border-image-source: linear-gradient(to right, #333333 0%, #333333 3px, #dcdcdc 3px);
    border-image-slice: 1 0 0 0; }
    .st-theme-sourceforge.st-vertical > .nav .nav-link {
      border-width: 0 1px 1px 3px;
      border-image-source: linear-gradient(to right, #333333 0%, #333333 3px, #dcdcdc 3px);
      border-image-slice: 0 1 1 3; }
      .st-theme-sourceforge.st-vertical > .nav .nav-link.active {
        border-image-source: linear-gradient(to right, #00ccff 0%, #00ccff 3px, #dcdcdc 3px);
        border-image-slice: 0 0 1 3; }
  